<template>
  <v-navigation-drawer
    v-model="show"
    app
    :class="{ 'block-loading': blockLoading }"
  >
    <div class="mr-auto pt-5 pl-3 d-flex">
      <mcs-btn
        v-if="$vuetify.breakpoint.mobile"
        icon
        class="mr-3"
        prependIcon="mdi-keyboard-backspace"
        color="black"
        @click="show = false"
      >
      </mcs-btn>
      <div
        :style="{ 'max-width': logoMaxWidth + 'px' }"
        style="max-height: 75px"
      >
        <img
          :src="logo"
          style="max-height: 80px; max-width: 100%"
          class="hover-pointer"
          @click="logoClick"
        />
      </div>
    </div>

    <div class="mt-7">
      <div
        v-if="showUpgrade"
        v-ripple
        class="border-thick border-primary border rounded-lg pa-2 mx-3 primary--text hover-pointer"
        @click="upgrade"
      >
        <div class="d-flex justify-space-between">
          <div>
            <v-icon color="primary"> mdi-crown-outline </v-icon>
            Upgrade to pro
          </div>
          <v-icon
            v-if="showUpgradeArrow"
            color="primary"
          >
            mdi-arrow-right
          </v-icon>
        </div>
      </div>

      <mcs-alert
        v-if="runningAs"
        color="accentLight"
        class="mx-3 overflow-hidden"
        dismissible
        @dismiss="cancelRunas"
      >
        <div class="body-2">
          <v-icon
            small
            class="mr-1"
          >
            mdi-account-supervisor-circle </v-icon
          >Running as user:
          <div class="text-truncate font-weight-bold caption">
            {{ runningAs }}
          </div>
        </div>
      </mcs-alert>

      <v-list
        class="px-2"
        dense
      >
        <template v-for="item in mainItemsToRender">
          <v-list-item
            v-if="!item.children"
            :key="item.title"
            link
            :to="item.route"
            active-class="nav-active"
            exact-path
            :ripple="false"
            class="mb-1"
            v-on="item.click ? { click: item.click } : {}"
          >
            <v-list-item-icon>
              <v-icon class="thick">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="item.route"
            :prepend-icon="item.icon"
            no-action
            class="mb-1"
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.children"
              :key="child.title"
              link
              :to="child.route"
              :disabled="child.disabled"
              exact-path
            >
              <v-list-item-content>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </div>

    <template #append>
      <v-list
        class="mt-5 px-2"
        dense
      >
        <v-list-item
          v-for="item in footerItemsToRender"
          :key="item.title"
          :link="item.route !== null"
          :to="item.route"
          v-on="item.click ? { click: item.click } : {}"
        >
          <v-list-item-icon>
            <v-icon class="thick">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { APP_EVENTS, DRAWERS, ROUTES } from '@/utils/constants';
import collections from '@/utils/mixins/collections';
import general from '@/utils/mixins/general';
import runas from '@/utils/mixins/runas';
import { mapGetters } from 'vuex';

export default {
  name: 'Nav',

  mixins: [runas, collections, general],

  data() {
    return {
      show: false,
      beaconId: 'b1774456-e1e1-4335-bf72-d1c8d069f462'
    };
  },

  computed: {
    logo() {
      return this.$store.state.config.logoUrlDark;
    },

    logoMaxWidth() {
      if (this.$vuetify.breakpoint.mobile) return '110';
      else if (!this.isMCS) return '230';
      return 110;
    },

    runningAs() {
      return this.$store.state.config.runas;
    },

    showUpgrade() {
      if (!this.enableSubscriptions) return false;
      if (!this.$store.state.user.plan.isSubscriber) return true;
      if (
        this.$store.state.user.plan.isSubscriber &&
        this.$store.state.user.plan.subscriptionIsCanceled
      ) {
        return true;
      }
      return false;
    },

    showUpgradeArrow() {
      return (
        this.$store.state.config.route.path === ROUTES.SUBSCRIPTION.PATH &&
        !this.$vuetify.breakpoint.mobile
      );
    },

    blockLoading() {
      return this.$store.state.config.blockUILoading;
    },

    mainItems() {
      var items = [
        {
          name: 'Dashboard',
          icon: 'mdi-home',
          route: ROUTES.DASHBOARD.PATH
        },
        {
          name: 'Projects',
          route: ROUTES.PROJECTS.PATH,
          icon: 'mdi-view-dashboard-variant'
        },
        // we will insert the design menu item here
        {
          name: 'Printing',
          icon: 'mdi-printer',
          route: ROUTES.PRINT.PATH,
          render: this.enablePrint && this.enableMcsTemplates
        },
        {
          name: 'Campaign Ideas',
          icon: 'mdi-map-marker',
          route: this.getCampaignIdeasLink(),
          render: this.enableCampaignIdeas
        },
        {
          name: 'Nonprofit Details',
          icon: 'mdi-heart-plus',
          click: this.goNonProfit,
          render: this.isMissionPrint
        },
        {
          name: 'Orders',
          icon: 'mdi-receipt-text',
          route: ROUTES.ORDER_HISTORY.PATH,
          render: this.hasOrdered
        },
        {
          name: 'Billing',
          icon: 'mdi-credit-card-outline',
          render:
            ((this.enableSubscriptions || this.hasOrdered || this.isMasterAccountOwner) &&
              !this.isTeamsUser) ||
            this.isCollectionOwner,
          children: [
            {
              name: 'Subscription',
              route: ROUTES.SUBSCRIPTION.PATH,
              render: this.enableSubscriptions
            },
            {
              name: 'Subscription',
              route: ROUTES.ADMIN_SUBSCRIPTION.PATH,
              render: this.isMasterAccountOwner && !this.isMCS
            },
            {
              name: 'Cards',
              route: ROUTES.CARDS.PATH,
              render: this.hasOrdered || this.isMasterAccountOwner
            }
          ]
        },
        {
          name: 'Team' + (this.collections_to_manage.length > 1 ? 's' : ''),
          icon: 'mdi-account-group',
          render: !!this.collections_to_manage.length && !this.isMasterAccountOwner,
          route: {
            path: this.getTeamLink()
          }
        },
        {
          name: 'Contributor',
          icon: 'mdi-handshake',
          render:
            this.isAdmin || this.isDesigner || this.isAssetContributor || this.isImageContributor,
          children: [
            {
              name: 'Design Projects',
              route: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.PATH.replace(':statusId', 'claimed'),
              render: this.isAdmin || this.isDesigner
            },
            {
              name: 'Asset Projects',
              route: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.PATH.replace(':statusId', 'claimed'),
              render: this.isAdmin || this.isAssetContributor
            },
            {
              name: 'Image Projects',
              route: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.PATH.replace(':statusId', 'claimed'),
              render: this.isAdmin || this.isImageContributor
            },
            {
              name: 'Customer Projects',
              route: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.PATH.replace(':statusId', 'open'),
              render: this.isAdmin || this.isDesigner
            },
            {
              name: 'Payouts',
              route: ROUTES.CONTRIBUTOR_PAYOUTS.PATH
            }
          ]
        },
        {
          name: 'Admin',
          icon: 'mdi-shield-account-variant',
          render: this.isMasterAccountOwner,
          children: [
            {
              name: 'Teams',
              route: { name: ROUTES.ADMIN_TEAMS.NAME }
            },
            {
              name: 'People',
              route: { name: ROUTES.ADMIN_PEOPLE.NAME },
              render: !this.enableApi || this.isMCS
            },
            {
              name: 'Orders',
              route: this.getOrdersLink(),
              render: this.enablePrintReselling
            },
            {
              name: 'Design Feed',
              route: ROUTES.ADMIN_DESIGN_FEED.PATH,
              render: !this.enableApi
            },
            {
              name: 'Settings',
              route: { name: ROUTES.ADMIN_SETTINGS.NAME },
              render: (this.enablePortal || this.isMCS) && !this.enableApi
            },
            {
              name: 'Theme',
              route: { name: ROUTES.ADMIN_THEME.NAME },
              render: this.enableApi
            },
            {
              name: 'Tax Exemptions',
              route: { path: ROUTES.ADMIN_EXEMPTION_CERTS.PATH.replace(':statusId', 'all') },
              render: this.isMasterAccountOwner && this.isMissionPrint
            },
            {
              name: 'Api Docs',
              route: { path: ROUTES.ADMIN_API_DOCS.PATH.replace(':id', 'getting-started') }
            },
            {
              name: 'Master Accounts',
              route: { name: ROUTES.ADMIN_MASTER_ACCOUNTS.NAME },
              render: this.isAdmin
            },
            {
              name: 'Styles',
              route: { name: ROUTES.ADMIN_STYLES.NAME },
              render: this.isAdmin
            }
          ]
        }
      ];
      return items;
    },

    mainItemsToRender() {
      var items = [];
      this.mainItems.forEach((parent) => {
        if (parent.render !== false) {
          var item = parent;
          if (item.children) {
            var children = [];
            item.children.forEach((child) => {
              if (child.render !== false) {
                children.push(child);
              }
            });
            item.children = children;
          }
          items.push(item);
        }
      });
      return items;
    },

    footerItems() {
      var items = [
        {
          name: 'Help Center',
          icon: 'mdi-help-circle',
          click: this.help,
          render: this.$store.state.config.helpscoutReady
        },
        {
          name: !this.runningAs ? 'Run As' : 'Cancel Run As',
          icon: !this.runningAs ? 'mdi-account-key-outline' : 'mdi-account-off-outline',
          click: this.runasClicked,
          render: this.isAdmin
        },
        {
          name: 'Logout',
          icon: 'mdi-logout',
          click: this.logout
        }
      ];
      return items;
    },

    footerItemsToRender() {
      return this.footerItems.filter((x) => x.render !== false);
    },

    ...mapGetters({
      enablePrint: 'user/enablePrint',
      enableCampaignIdeas: 'user/enableCampaignIdeas',
      isAdmin: 'user/isAdmin',
      enableApi: 'user/enableApi',
      enablePortal: 'user/enablePortal',
      isMCS: 'user/isMCS',
      isMasterAccountOwner: 'user/isMasterAccountOwner',
      isMissionPrint: 'user/isMissionPrint',
      hideCollectionDesigns: 'admin/collections/hideCollectionDesigns',
      isImageContributor: 'user/isImageContributor',
      isAssetContributor: 'user/isAssetContributor',
      isDesigner: 'user/isDesigner',
      enableSubscriptions: 'config/enableSubscriptions',
      enablePrintReselling: 'user/enablePrintReselling',
      enableMcsTemplates: 'user/enableMcsTemplates',
      hasOrdered: 'user/hasOrdered',
      isCollectionOwner: 'user/isCollectionOwner',
      isTeamsUser: 'user/isTeamsUser',
      collections_to_manage: 'user/collections_to_manage'
    })
  },

  mounted() {
    this.$eventBus.$on(APP_EVENTS.SET_NAV_VISIBILITY, (val) => {
      this.show = val;
    });
    this.show = !this.$vuetify.breakpoint.mobile;
  },

  methods: {
    logoClick() {
      if (this.$portalMode()) {
        if (this.$store.state.user.masterAccount.homePageUrl) {
          window.location.href = this.$store.state.user.masterAccount.homePageUrl;
        } else {
          this.$router.push(ROUTES.DASHBOARD.PATH);
        }
      } else {
        window.location = this.$store.state.config.webUrl;
      }
    },

    help() {
      this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT);
    },

    goNonProfit() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.NON_PROFIT_DRAWER);
    },

    cancelRunas() {
      this.$store.commit('config/SET_RUNNING_AS', null);
      this.$router.go(ROUTES.DASHBOARD.PATH);
    },

    runasClicked() {
      if (!this.runningAs) {
        this.runas(null);
      } else {
        this.cancelRunas();
      }
    },

    upgrade() {
      this.$router.push(ROUTES.SUBSCRIPTION.PATH);
    },

    logout() {
      window.location = this.$store.state.config.webUrl + 'account/logout';
    },

    getTeamLink() {
      if (this.collections_to_manage.length > 1) {
        return ROUTES.ADMIN_TEAMS.PATH;
      }
      return this.getCollectionsDetailPath(
        null,
        null,
        this.collections_to_manage.map((x) => x.collectionId)[0]
      );
    }
  }
};
</script>

<style>
.v-list-item__title {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.nav-active .v-list-item__title {
  font-weight: 700 !important;
}
.v-list-item::before {
  border-radius: 7px !important;
}
.nav-active::before,
.v-list-item:hover::before,
.v-list-item:focus::before {
  background-color: #aaa5ae;
}
.v-list-item__icon:first-child {
  margin-right: 18px !important;
}
.v-alert.overflow-hidden .v-alert__content {
  overflow: hidden;
}
.nav-active {
  color: var(--v-primary-base) !important;
}
.nav-active::before {
  opacity: 0.1 !important;
  background-color: var(--v-primary-base) !important;
}
.v-list-group__header.v-list-item--active {
  color: black;
}
</style>
